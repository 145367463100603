import i18n from '@/i18n/index'

export default {
  formData: [{
    span: 16,
    prop: 'name',
    name: 'input',
    label: i18n.t('name'),
    placeholder: i18n.t('name'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'description',
    name: 'input',
    type: 'textarea',
    label: i18n.t('Description'),
    placeholder: i18n.t('Description'),
    Object: {
      rows: 4
    }
  }, {
    span: 16,
    prop: 'additional_information',
    name: 'input',
    type: 'textarea',
    label: i18n.t('Additional information'),
    placeholder: i18n.t('Additional information'),
    Object: {
      rows: 4
    }
  }],
  formData_pricing: [{
    span: 16,
    prop: 'tariff_type',
    name: 'select',
    options: [],
    value: [0],
    label: i18n.t('Type'),
    placeholder: i18n.t('Type'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'connection_fee',
    name: 'input',
    label: i18n.t('Connection fee'),
    placeholder: i18n.t('Connection fee'),
    Object: {
      label: 'A fixed fee that is appiled at the start of the charging session',
      visible: 'tariff_type',
      visibleValue: ''
    }
  }, {
    span: 16,
    prop: 'price_per_kwh',
    name: 'input',
    label: i18n.t('Price per kWh'),
    placeholder: i18n.t('Price per kWh'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'duration_fee_time_period',
    name: 'select',
    options: [],
    value: [0],
    label: i18n.t('Duration fee time period'),
    placeholder: i18n.t('Duration fee time period'),
    Object: {
      label: 'Defines the time-period for the duration fee.Example:If you select 15 min then the EV driver is billed the duration fee at the start of every 15 min of the charging session'
    }
  }, {
    span: 16,
    prop: 'fee_per_time_period',
    name: 'input',
    label: i18n.t('Fee per time period'),
    placeholder: i18n.t('Fee per time period'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'duration_fee_grace_period',
    name: 'input',
    label: i18n.t('Duration fee grace period(min)'),
    placeholder: i18n.t('Duration fee grace period(min)'),
    Object: {
      label: 'A grace period at the beginning of the charging session. during which the duration fee per time-period does not apply. This can beused to start applying a duration fee after certain amount of time'
    }
  }, {
    span: 16,
    prop: 'idle_fee_per_minute',
    name: 'input',
    label: i18n.t('Idle fee per minute'),
    placeholder: i18n.t('Idle fee per minute'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'idle_fee_grace_period',
    name: 'input',
    label: i18n.t('Idle fee grace period(min)'),
    placeholder: i18n.t('Idle fee grace period(min)'),
    Object: {
      label: 'Optional: When idle period (no-charging) is detected during a seesion the user is given this grace period remove the vehicle.before idle fee starts applying'
    }
  }, {
    span: 16,
    prop: 'pre_authorize_amount',
    name: 'input',
    label: i18n.t('Pre-authorize amount'),
    placeholder: i18n.t('Pre-authorize amount'),
    Object: {
      label: "The amount that would be blocked on the user's card at the start of the session.Leave empty for no requirement settings"
    }
  }, {
    span: 16,
    prop: 'min_price',
    name: 'input',
    label: i18n.t('Min price'),
    placeholder: i18n.t('Min price'),
    Object: {
      label: 'Sets a minimum total amount per session.This is helpful in the case your payment service provider has a minimum transaction size or high transaction fees and you want to make sure to have a positive ROI per transaction'
    }
  }],
  formData_restrictions: [{
    span: 16,
    prop: 'users_of_cp_owner',
    name: 'checkbox',
    value: false,
    label: i18n.t('Users of the CP owner'),
    placeholder: i18n.t('Users of the CP owner'),
    Object: {
      label: 'Makes the tariff apply to users of the partner.who is set as owner of the charge point'
    }
  }, {
    span: 16,
    prop: 'users_of_all_roaming_partners',
    name: 'checkbox',
    value: false,
    label: i18n.t('Users of all roaming Partners'),
    placeholder: i18n.t('Users of all roaming Partners'),
    Object: {
      label: 'Makes the tariff apply to users of all eMSPs,which are roaming partners'
    }
  }, {
    span: 16,
    prop: 'ad_hoc_users',
    name: 'checkbox',
    value: false,
    label: i18n.t('Ad_hoc users(non-registered)'),
    placeholder: i18n.t('Ad_hoc users(non-registered)'),
    Object: {
      label: 'Makes the tariff valid for non-registered users[ad-hoc-charging]'
    }
  }, {
    span: 16,
    prop: 'user_group_ids',
    options: [],
    name: 'select',
    label: i18n.t('User Group'),
    placeholder: i18n.t('User Group'),
    Object: {
      filterable: true,
      multiple: true,
      name: 'name',
      value: 'id'
    }
  }],
  formData_stopSession: [{
    span: 16,
    prop: 'session_max_time',
    name: 'input',
    label: i18n.t('Ssession max time'),
    placeholder: i18n.t('Ssession max time'),
    Object: {
      label: 'The maximum minutes a session is allowed to run.Leave empty for no time limit'
    }
  }, {
    span: 16,
    prop: 'energy_exceeds',
    name: 'input',
    label: i18n.t('Energy exceeds'),
    placeholder: i18n.t('Energy exceeds'),
    Object: {
      label: 'The maximum energy [kWh] allowed per session. Leave empty for no limit'
    }
  }, {
    span: 16,
    prop: 'stop_when_pre_authorization_amount_falls_below',
    name: 'input',
    label: i18n.t('Stop when Pre-authorization amount falls below'),
    placeholder: i18n.t('Stop when Pre-authorization amount falls below'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'minimum_balance_required_to_start_session',
    name: 'input',
    label: i18n.t('Minimum balance required to start session'),
    placeholder: i18n.t('Minimum balance required to start session'),
    Object: {
      label: 'The user will NOT be able to start a session unless there is at least this amount in their balance.If the amount is lower than that the user will be asked to first top up their balance before starting a session.Leave empty for no requirements settings'
    }
  }, {
    span: 16,
    prop: 'automatically_stop_when_balance_fall_below',
    name: 'input',
    label: i18n.t('Automatically stop when balance fall below'),
    placeholder: i18n.t('Automatically stop when balance fall below'),
    Object: {
      label: 'Leave empty for no requirement'
    }
  }]
}
