<template>
  <div>
      <alter-form :customeButton="true" ref="alterform" :watchImageVisible="false" :modifyData="data" @formSubmit="formSubmit" :formData="formData"></alter-form>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
// import loading from '@/utils/loading.json'
import apis from '@/services/index'
import jsonData from '../jsonData'
// import constant from '@/utils/constant.js'
// import Utils from '@/utils/Utils.js'
// import apis from '@/services/index'

export default {
  name: 'restrictions',
  components: {
    alterForm
  },
  props: ['data'],
  data () {
    return {
      modifyData: {},
      formData: jsonData.formData_restrictions
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    async init () {
      const userGoup = await apis.TariffsBillings.getUserGroupSimpleList()
      this.formData[3].options = userGoup.data.user_groups
    },
    formSubmit (data) {
      const obj = JSON.parse(JSON.stringify(data))
      this.$emit('tabChange', obj, 'restrictions')
    }
  }
}
</script>

<style lang="scss" scope>
</style>
