<template>
  <div>
      <alter-form :customeButton="true" ref="alterform" :watchImageVisible="false" :modifyData="data" @formSubmit="formSubmit" :formData="formData"></alter-form>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
import jsonData from '../jsonData'

export default {
  name: 'pricing',
  components: {
    alterForm
  },
  props: ['data'],
  data () {
    return {
      modifyData: {},
      formData: jsonData.formData_pricing,
      tariff_type_options: [{
        name: 'Charging NOT allowed',
        value: 'charging not allowed'
      }, {
        name: 'Free',
        value: 'free'
      }, {
        name: 'Flat rate',
        value: 'Flat Rate'
      }, {
        name: 'Duration + energy',
        value: 'Duration + energy'
      }],
      time_period: [{
        name: '1 min',
        value: '1 min'
      }, {
        name: '15 min',
        value: '15 min'
      }, {
        name: '30 min',
        value: '30 min'
      }, {
        name: '60 min',
        value: '60 min'
      }, {
        name: '240 min',
        value: '240 min'
      }]
    }
  },
  watch: {
  },
  created () {
    // console.log('--------', this.formData)
    this.formData[0].options = this.tariff_type_options
    this.formData[3].options = this.time_period
  },
  mounted () {
  },
  methods: {
    formSubmit (data) {
      const obj = JSON.parse(JSON.stringify(data))
      this.$emit('tabChange', obj, 'pricing')
    }
  }
}
</script>

<style lang="scss" scope>
</style>
